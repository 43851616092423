import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import { general_config } from '../../../../../../general_utils/general_configuration';
import YearlySpatialAux from './utils/yearlyspatialaux';
import { getUniqueSortedYearsFromDate } from './utils/yearly_aux';

import { productResponseToArrays, axiosCall } from '../../../../../../general_utils/functions';
import DateManager from '../../../../../../general_utils/datemanager';

import YearlyGraphButtonGroup from './components/YearlyGraphButtonGroup';
import ContainerOfPlaceholders from '../../../../../../general_components/ContainerOfPlaceholders';
import YearlyGraphPlot from './components/YearlyGraphPlot';

const datemanager = new DateManager();
const yearlyspatialaux = new YearlySpatialAux();

function YearlyComparisonGraph({ catchment: catchmentName, catchmentGeoJson }) {

  const [productName, setProductName] = useState(general_config.default_product);

  /**
   * States for the interactive average calculation
   */
  const [availableWaterYears, setAvailableWaterYears] = useState([])
  const [availableData, setAvailableData] = useState(null);
  const [startYearForAverageCalculation, setStartYearForAverageCalculation] = useState(null);
  const [endYearForAverageCalculation, setEndYearForAverageCalculation] = useState(null);

  yearlyspatialaux.getWaterYearStartAndEndFromGeoJson(catchmentGeoJson);

  useEffect(() => {
    const [toDate, fromDate] = datemanager.getToFrom(datemanager.numOfTotalDays, yearlyspatialaux.water_year_start_month_and_day);
    
    axiosCall({ catchment: catchmentName, productName: productName.toLowerCase(), to: toDate, from: fromDate })
      .then(res => productResponseToArrays(res))
      .then(([...response]) => {
        const availableDates = response[0];
        const availableValues = response[1]; 
        setAvailableData({'dates': availableDates , 'values' : availableValues});

        const availableYears = getUniqueSortedYearsFromDate(availableDates);
        // Split data format to ploty in another section so we know what is data itself and what is to prepare it for ploty (split steps)
        setAvailableWaterYears(DateManager.getWaterYearsArray(yearlyspatialaux.water_year_start_month_and_day, availableYears.length));

      });
  }, [productName]);
  
  if (!availableData) {
    return (
      <ContainerOfPlaceholders
        numOfPlaceholders={10}
      />
    )

  } else {

    return (
      <Row>
        <YearlyGraphButtonGroup
          startYearForAverageCalculation={startYearForAverageCalculation}
          endYearForAverageCalculation={endYearForAverageCalculation}
          setStartYearForAverageCalculation={setStartYearForAverageCalculation}
          setEndYearForAverageCalculation={setEndYearForAverageCalculation}
          availableWaterYears={availableWaterYears}
          setProductName={setProductName}
          productName={productName}
          catchmentName={catchmentName}
          availableDates={availableData['dates']}
          availableValues={availableData['values']}
          numberOfYearsAvailable={availableWaterYears.length - 1}
        />
        <YearlyGraphPlot
          startYearForAverageCalculation={startYearForAverageCalculation}
          endYearForAverageCalculation={endYearForAverageCalculation}
          waterYearStart = {yearlyspatialaux.water_year_start_month_and_day}
          availableDates={availableData['dates']}
          availableValues={availableData['values']}
          productName={productName}
          availableWaterYears={availableWaterYears}
        />
      </Row>
    )
  }
}

export default YearlyComparisonGraph;